import {
  ActionButton,
  Button,
  ButtonGroup,
  Content,
  Dialog,
  DialogTrigger,
  Divider,
  Flex,
  Heading,
  Item,
  ProgressCircle,
  TabList,
  Tabs,
  Text,
  TextField,
  View,
  Form,
  Header
} from "@adobe/react-spectrum";
import { error, success, warning } from "@react/react-spectrum/Toast";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { PickerContext } from "../../contexts/GlobalState";
import Accounting from "./tabs/Accounting";
import Assignees from "./tabs/Assignees";
import Information from "./tabs/Information";
import InformationLite from "./tabs/InformationLite";
import PsrSettings from "./tabs/Settings";
import SuiteID from "./tabs/SuiteID";
import Edit from '@spectrum-icons/workflow/Edit';
import SaveFloppy from "@spectrum-icons/workflow/SaveFloppy";

import Ajv from "ajv";
import api from "../../api/api";
import { IMSContext } from "../../contexts/IMSContext";
import { IJIRAIssue, IPSRForm } from "../../models/psr.model";
import { PSRSchema } from "./psr.schema";

// this is our FORM OBJECT that we have to maintain throughout the tabs in the add psr
const defaultData1: IPSRForm = {
  process_id: null,
  server_id: null,
  client_id: null,
  primaryEngineer_id: null,
  secondaryEngineer_id: null,
  manager_id: null,
  teamLead_id: null,
  owner_id: null,
  process_name: null,
  ppath: null,
  runas: null,
  language: null,
  startdate: null,
  crontab: null,
  process_duration: null,
  github_repo: null,
  scope: null,
  puma_enabled: null,
  badger_enabled: null,
  process_summary: null,
  stamp: new Date().toDateString(),
  acm: null,
  enabled: 0,
  region: null,
  classification_id: null,
  annual_recurring_revenue: null,
  rsid: null,
  datafeed: null,
  account_info: [],
};

const defaultJIRAData: IJIRAIssue = {
  project_id: null,
  client_name: null,
  description: null,
  engineer_name: null,
  priority: null,
  project_name: null,
  summary: null,
}

const steps = [
  "PSR Information",
  "Accounting",
  "PSR Settings",
  "Associated Report Suite Ids",
  "Assignees",
];

// the functional component that helps in rendering all of the ADD NEW PSR functionality
const PSRForm: React.FC<{}> = (): any => {
  let history = useHistory();
  let [isLoading, setIsLoading] = useState(false);
  const [jiraData, setJiraData] = useState(defaultJIRAData as IJIRAIssue)
  const [formData, setFormData] = useState(defaultData1 as IPSRForm);
  const [TabId, setTabId] = useState(1);
  const [formView, setFormView] = useState({
    buttonTitle: "",
    isReadOnly: false,
  });

  const { defaultData } = useContext(PickerContext);
  const { isAdmin, isEngineer } = useContext(IMSContext);

  const onClickNext = (Id: number) => setTabId(Id);
  const onClickPrev = (Id: number) => setTabId(Id);
  let { type, id } = useParams<any>();
  const steps_items = steps.map((step, index) => {
    return { id: index + 1, name: step };
  });

  // we have to pass some common properties throughout all the functional components
  const props = {
    formData,
    setFormData,
    onClickNext,
    onClickPrev,
    type,
    formView,
  };

  let psrId = id;

  console.log(` Type: ${type}`, `id: ${psrId}`);

  useEffect(() => {
    async function fetchPsr() {
      setIsLoading(true);
      console.log("it is view or edit");
      const projectRes = await api.fetchProjectById(psrId);
      console.log("Got the project", projectRes);
      setFormData(projectRes);

      if (type === "view") {
        console.log("it is view");
        setFormView({ buttonTitle: "Edit PSR", isReadOnly: true });
      } else {
        console.log("it is edit");
        setFormView({ buttonTitle: "Save Changes", isReadOnly: false });
      }
      setIsLoading(false);
    }

    if (id) {
      fetchPsr();
    } else {
      console.log("it is add");
      setFormView({ buttonTitle: "Add", isReadOnly: false });
      setFormData(defaultData1);
    }
  }, [type]);

  // helps us to decide which tab has to be rendered based on the TabId variable we maintain
  const display = (id: number) => {
    switch (id) {
      case 1:
        return <Information {...props} />;
      case 2:
        return <Accounting {...props} />;
      case 3:
        return <PsrSettings {...props} />;
      case 4:
        return <SuiteID {...props} />;
      case 5:
        return <Assignees {...props} />;
      default:
        return <Information {...props} />;
    }
  };

  function validateFields() {
    const schema = PSRSchema;
    const ajv = new Ajv();
    const validate = ajv.compile(schema);
    const valid = validate(formData);
    if (valid) {
      console.log("The data is valid");
      return true;
    } else {
      console.log("The data is not valid", validate.errors);
      return false;
    }
  }

  async function handleJIRASubmit() {

    jiraData.client_name = formData.client_id;
    jiraData.project_id = formData.process_id;
    jiraData.project_name = formData.process_name;

    console.log("JIRA submission:", jiraData);
    try {
      setIsLoading(true);
      const response = await api.createJIRA(jiraData);
      success("JIRA Issue Created!", {
        actionLabel: response.key,
        onAction: () => {
          console.log('Toast action button clicked', response.url);
          window.open(response.url, '_blank');
        },
        onClose: () => console.log("close"),
        timeout: 0
      });
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      error("Failed to create a JIRA issue!", {
        onClose: () => console.log("close"),
        timeout: 3000,
      });
      setIsLoading(false);
    }

  }

  async function handleSubmit() {
    console.log("submit form data: ", formData);
    if (type === "add") {
      let validFlag = validateFields();
      if (validFlag) {
        try {
          const response = await api.createProject(formData);
          console.log(response)
          if (response.success) {
            success(`PSR entered successfully! PSR ID:${response.processId}`, {
              onClose: () => console.log("close"),
              timeout: 10000,
            });
            history.push(`/view-all-psr`);
          }
        } catch (err) {
          console.log(err);
          error("Failed to enter PSR!", {
            onClose: () => console.log("close"),
            timeout: 3000,
          });
        }
      } else {
        warning("Some fields are missing", {
          onClose: () => console.log("close"),
          timeout: 3000,
        });
      }
    } else if (type === "edit") {
      try {
        await api.updateProject(formData);
        success("Record Updated Successfully!", {
          timeout: 3000,
        });
      } catch (err) {
        console.log(err);
        error("Failed to Update!", {
          timeout: 3000,
        });
      }
    }
  }

  const JIRAButton = <DialogTrigger type="modal">
    <ActionButton>Create JIRA Issue</ActionButton>
    {(close) => (
      <Dialog>
        <Heading>Create a JIRA Issue</Heading>
        <Divider />
        <Content>
          <Form>
            <TextField label="Title" onChange={(value) => {
              setJiraData({
                ...jiraData,
                summary: value
              });
            }} />
            <TextField label="Description" onChange={(value) => {
              setJiraData({
                ...jiraData,
                description: value
              });
            }} />
            <TextField label="Assignee" onChange={(value) => {
              setJiraData({
                ...jiraData,
                engineer_name: value
              });
            }} />
          </Form>
        </Content>
        <ButtonGroup>
          <Button variant="secondary" onPress={close}>
            Cancel
          </Button>
          <Button variant="accent" onPress={handleJIRASubmit} onPressEnd={close} autoFocus>
            Create
          </Button>
        </ButtonGroup>
      </Dialog>
    )}
  </DialogTrigger>

  const displayButtons = () => {
    if (type === "view" && isAdmin) {
      return (
        <>
          {/* {JIRAButton} */}
          <Button
            variant="cta"
            style="outline"
            onPress={() => {
              history.push(`/psr-form/edit/${id}`);
            }}
            width={100}
          >
            <Edit />
            <Text>Edit</Text>
          </Button>
          <ActionButton
            onPress={() => {
              history.push(`/view-all-psr`);
            }}
            marginStart="size-100"
            width={100}
          >
            Back
          </ActionButton>
        </>
      );
    } else if (type === "edit" && isAdmin) {
      return (
        <>
          {/* {JIRAButton} */}
          <Button variant="cta" onPress={handleSubmit}>
            <SaveFloppy />
            <Text>
              Save Changes
            </Text>
          </Button>
          <Button
            variant="secondary"
            onPress={() => {
              history.push(`/psr-form/view/${id}`);
            }}
            marginStart="size-100"
          >
            Cancel
          </Button>
        </>
      );
    } else if (type === "add" && isAdmin) {
      return (
        <>
          <Button variant="cta" onPress={handleSubmit}>
            Submit
          </Button>
          <Button
            variant="secondary"
            onPress={() => {
              history.push(`/view-all-psr`);
            }}
            marginStart="size-100"
          >
            Cancel
          </Button>
        </>
      );
    } else {
      return (
        <>
          {/* {JIRAButton} */}
          <ActionButton
            onPress={() => {
              history.push(`/view-all-psr`);
            }}
            marginStart="size-100"
            width={100}
          >
            Back
          </ActionButton>
        </>
      );
    }
    return <></>;
  };

  const switchTab = (num: number) => {
    let numFinal = TabId + num;
    if (numFinal < 1) {
      numFinal = 1;
    } else if (numFinal > 5) {
      numFinal = 5;
    }
    setTabId(numFinal);
  };

  if (isLoading) return <ProgressCircle isIndeterminate />;

  const renderProjectForm = () => {
    if (defaultData) {
      if (!isEngineer && !isAdmin)
        return (
          <Flex width="100%" direction="column">
            <View
              alignSelf="end"
              marginEnd="size-800"
              backgroundColor="static-white"
            >
              {displayButtons()}
            </View>
            <View>
              <InformationLite {...props} />
            </View>
          </Flex>
        );

      return (
        <Flex width="100%" direction="column">
          <View
            // alignSelf="end"
            marginStart="10%"
            marginEnd="size-800"
            backgroundColor="static-white"
          >
            <Flex direction="row" justifyContent="space-between">
              <View
                alignSelf="start"
              >
                {
                  props?.formData?.process_id && <Heading level={2}> Puma ID: {props?.formData?.process_id}</Heading>
                }

              </View>
              <View
                alignSelf="end"
              >
                {displayButtons()}
              </View>
            </Flex>
          </View>
          <View>
            <Tabs
              aria-label="tabs"
              items={steps_items}
              onSelectionChange={(key: any) => {
                setTabId(key);
              }}
              selectedKey={TabId}
              marginStart="10%"
              // marginTop="2%"
              width="82%"
            >
              <TabList flex="1 1 auto" minWidth="0px">
                {(item: any) => <Item>{item.name}</Item>}
              </TabList>

              {display(TabId)}
            </Tabs>
          </View>
          <View alignSelf="center" marginTop="size-200">
            <Button
              variant="primary"
              width="size-150"
              onPress={() => switchTab(-1)}
              marginEnd="size-100"
            >
              Prev
            </Button>
            {
              TabId != 5 ? <Button
                variant="primary"
                width="size-150"
                onPress={() => switchTab(+1)}
                marginStart="size-100"
              >
                Next
              </Button> : <></>
            }

          </View>
        </Flex>
      );
    }
    return <></>;
  };

  return renderProjectForm();
};

export default PSRForm;
