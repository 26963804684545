import { Checkbox, ComboBox, Form, Item, Picker, TextArea, TextField } from "@adobe/react-spectrum";
import React, { useContext, useState } from "react";
import { PickerContext } from "../../../contexts/GlobalState";

const comboRegion = [
  { region: "Unassigned" },
  { region: "North America" },
  { region: "EMEA" },
  { region: "APAC" },
];

// component to be rendered
const Informationpsr = ({
  formData, setFormData,
  onClickNext,
  formView,
  type,
}) => {

  let [isValid, setValid] = useState(false);
  let [value, setValue] = useState("");
  const onChange = (newVal) => {
    const regex = /^\/(([a-zA-z-]+)([0-9])*(\.(php))?\/?)*$/.test(newVal);
    setValid(newVal.length <= 200 && regex ? true : false);
    setValue(newVal);
    setFormData({ ...formData, ppath: newVal });
    console.log(isValid);
  };

  const { defaultData } = useContext(PickerContext);


  return (
    <>

      {defaultData && formData ? (
        <Form
          aria-label="Get PSR Information"
          width="50%"
          labelPosition="top"
          labelAlign="start"
          marginStart="25%"
          necessityIndicator="icon"
          isReadOnly={formView.isReadOnly ? true : false}
        >
          <Checkbox
            aria-label="Enabled"
            // label="Enabled"
            onChange={(value) =>
              setFormData({ ...formData, enabled: value ? 1 : 0 })
            }
            isSelected={formData.enabled == 1}
          > Enabled
          </Checkbox>

          {/* <TextField isDisabled label="Last Message" /> */}
          <TextField
            label="Process ID"
            value={formData.process_id}
            onChange={(value) => {
              setFormData({ ...formData, process_id: value });
            }}
            isDisabled
            isReadOnly={true}
          />

          <TextField
            label="PSR Name (Max Length: 100)"
            maxLength={100}
            isRequired
            value={formData.process_name}
            //onPress={setData.Psrname}
            onChange={(value) => {
              setFormData({ ...formData, process_name: value });
            }}
            errorMessage={"Length must be within 20"}
            // onChange={handleChange("PSR Name", value)}
            autoComplete="off"
          />
          {/* {
              errors.PSRname && <p>{errors.PSRname}</p>
            } */}

          <TextArea
            // maxLength={200}
            isRequired
            label="Summary"
            value={formData.process_summary}
            //validationState={formData.process_summary ? "valid" : "invalid"}
            onChange={(value) => {
              setFormData({ ...formData, process_summary: value });
            }}
            //onChange={handleChange("Summary", value)}
            autoComplete="off"
          //placeholder="Brief"
          />
          {/* {
              maxLength >20 && <p>{"Length must be within 20"}</p>
            } */}
          {/* <p>{formData.client_id}</p> */}
          <ComboBox
            label="Client"
            isRequired
            defaultItems={defaultData.clients}
            //validationState={formData.client_id ? "valid" : "invalid"}
            selectedKey={formData.client_id}
            onSelectionChange={(selected) => {
              setFormData({ ...formData, client_id: selected });
            }}
          //onSelectionChange={(selected) => setMajorId(selected)}
          >
            {(item: any) => <Item key={item.client_id}>{item.client_name}</Item>}
          </ComboBox>

          <ComboBox
            label="Project Classification"
            isRequired
            defaultItems={defaultData.pct}
            selectedKey={formData.classification_id}
            //validationState={formData.classification_id ? "valid" : "invalid"}
            onSelectionChange={(selected) => {
              setFormData({ ...formData, classification_id: selected });
            }}
          //onSelectionChange={(selected) => setMajorId(selected)}
          >
            {(item: any) => (
              <Item key={item.classification_id}>
                {item.classification_name}
              </Item>
            )}
          </ComboBox>

          <Picker
            label="Region"
            isRequired
            items={comboRegion}
            defaultSelectedKey={formData.region}
            //validationState={formData.region ? "valid" : "invalid"}
            selectedKey={formData.region}
            onSelectionChange={(selected) =>
              setFormData({ ...formData, region: selected })
            }
          >
            {(item) => <Item key={item.region}>{item.region}</Item>}
          </Picker>

          <TextField
            label="Airflow URL"
            value={formData.ppath}
            onChange={onChange}
            // onChange={handleChange("Path", value)}
            // onBlur={onBlur}
            //@ts-ignore
            onSubmit={(value) => {
              setFormData({ ...formData, ppath: value });
            }}

          //placeholder="1234"
          />
          {!isValid && (
            <p>
              {" "}
              Path should be an absolute path. Eg:
              /raid-2/support/GSR_/main.php{" "}
            </p>
          )}

          <TextField
            isRequired
            label="Date Started/Added"
            // type="date"
            //placeholder="1234"
            value={
              type === "add"
                ? formData.startdate
                  ? formData.startdate
                  : new Date().toDateString()
                : ""
            }
          />

        </Form>
      ) : (
        ""
      )}
    </>
  );
};

export default Informationpsr;
