import {
  TableView,
  TableHeader,
  TableBody,
  Column,
  Row,
  Cell,
  Form,
  TextField,
  Flex,
  Button,
  DialogTrigger,
  Dialog,
  Content,
  Heading,
  DatePicker,
  NumberField
} from "@adobe/react-spectrum";
import React, { useContext, useState } from "react";
import { PickerContext } from "../../../contexts/GlobalState";
import { IPSRForm } from "../../../models/psr.model";
import { parseDate } from '@internationalized/date';
import Link from "@spectrum-icons/workflow/Link";

interface IPSRFormProp {
  formData: IPSRForm
  setFormData: any
  onClickNext: any
  onClickPrev: any
  formView: any
  type: any
}


// functional component
const Accounting = (props: IPSRFormProp) => {
  const {
    formData,
    setFormData,
    formView,
  } = props
  const { defaultData } = useContext(PickerContext);
  const [newAccountInfo, setNewAccountInfo] = useState({
    dr: "",
    year: "",
    start_date: null,
    end_date: null,
    link: "",
    revenue: 0,
    offshore_hours: 0,
    onshore_hours: 0,
    infra_cost: 0
  });

  const handleAddAccountInfo = () => {
    setFormData({
      ...formData,
      account_info: [...(formData.account_info || []), { ...newAccountInfo }],
    });
    setNewAccountInfo({
      dr: "",
      year: "",
      start_date: null,
      end_date: null,
      link: "",
      revenue: 0,
      offshore_hours: 0,
      onshore_hours: 0,
      infra_cost: 0
    });
  };

  const formatDate = (date) => {
    if (!date) return "-";
    const parsedDate = typeof date === "string" ? new Date(date) : date;
    return new Intl.DateTimeFormat("en-CA").format(parsedDate);
  };

  const onChange = (field_name, new_val) => {
    // const regex = /^\d*(\.\d{1,2})?$/.test(newVal);
    // setValid(newVal.length <= 13 && regex ? true : false);
    setFormData({ ...formData, [field_name]: new_val });
  };

  // Check if account_info exists and is an array
  const accountInfo = formData?.account_info || [];

  const columns = [
    { key: "dr", name: "DR" },
    { key: "year", name: "Year" },
    { key: "start_date", name: "Start Date" },
    { key: "end_date", name: "End Date" },
    { key: "revenue", name: "Revenue" },
    { key: "offshore_hours", name: "Offshore Hours" },
    { key: "onshore_hours", name: "Onshore Hours" },
    { key: "infra_cost", name: "Infra Cost" },
    { key: "link", name: "Link" }
  ];

  return (
    <>
      <Flex direction="column" gap="size-1000">
        <br />
        <Flex justifyContent="center">
          <DialogTrigger>
            {
              <Button variant="primary"
                isDisabled={formView.isReadOnly ? true : false}
              >Add Accounting Info</Button>
            }
            {(close) => (
              <Dialog>
                <Heading>Add Accounting Info</Heading>
                <Content>
                  <Form isRequired>
                    <TextField
                      label="DR"
                      value={newAccountInfo.dr}
                      onChange={(value) =>
                        setNewAccountInfo({ ...newAccountInfo, dr: value })
                      }
                    />
                    <TextField
                      label="Year"
                      value={newAccountInfo.year}
                      onChange={(value) =>
                        setNewAccountInfo({ ...newAccountInfo, year: value })
                      }
                    />
                    <DatePicker
                      label="Start Date"
                      value={newAccountInfo.start_date ? parseDate(newAccountInfo.start_date) : null}
                      onChange={(value) => {
                        setNewAccountInfo({ ...newAccountInfo, start_date: value?.toString() });
                      }}
                    />

                    <DatePicker
                      label="End Date"
                      value={newAccountInfo.end_date ? parseDate(newAccountInfo.end_date) : null}
                      onChange={(value) => {
                        setNewAccountInfo({ ...newAccountInfo, end_date: value?.toString() });
                      }}
                    />
                    <TextField
                      label="Link"
                      value={newAccountInfo.link}
                      onChange={(value) =>
                        setNewAccountInfo({ ...newAccountInfo, link: value })
                      }
                    />
                    <NumberField
                      label="revenue"
                      value={newAccountInfo.revenue}
                      onChange={(value) =>
                        setNewAccountInfo({ ...newAccountInfo, revenue: value })
                      }
                    />
                    <NumberField
                      label="offshore_hours"
                      value={newAccountInfo.offshore_hours}
                      onChange={(value) =>
                        setNewAccountInfo({ ...newAccountInfo, offshore_hours: value })
                      }
                    />
                    <NumberField
                      label="onshore_hours"
                      value={newAccountInfo.onshore_hours}
                      onChange={(value) =>
                        setNewAccountInfo({ ...newAccountInfo, onshore_hours: value })
                      }
                    />
                    <NumberField
                      label="infra_cost"
                      value={newAccountInfo.infra_cost}
                      onChange={(value) =>
                        setNewAccountInfo({ ...newAccountInfo, infra_cost: value })
                      }
                    />
                    <Flex justifyContent="end" gap="size-100">
                      <Button variant="secondary" onPress={close}>
                        Cancel
                      </Button>
                      <Button
                        variant="cta"
                        onPress={() => {
                          handleAddAccountInfo();
                          close();
                        }}
                      >
                        Add
                      </Button>
                    </Flex>
                  </Form>
                </Content>
              </Dialog>
            )}
          </DialogTrigger>
        </Flex>

        {defaultData && accountInfo.length > 0 && (
          <TableView
            aria-label="Accounting Information"
            selectionMode="none"
            width="100%"
            marginX="auto"
          // isReadOnly={formView.isReadOnly}
          >
            <TableHeader>
              {columns.map((column) => (
                <Column key={column.key}>{column.name}</Column>
              ))}
            </TableHeader>
            <TableBody>
              {accountInfo.map((data, index) => (
                <Row key={index}>
                  <Cell>{data.dr || "-"}</Cell>
                  <Cell>{data.year || "-"}</Cell>
                  <Cell>{formatDate(data.start_date)}</Cell>
                  <Cell>{formatDate(data.end_date)}</Cell>
                  <Cell>{data.revenue}</Cell>
                  <Cell>{data.offshore_hours}</Cell>
                  <Cell>{data.onshore_hours}</Cell>
                  <Cell>{data.infra_cost}</Cell>
                  <Cell><Button variant="primary" onPress={() => window.open(data.link, "_blank")} UNSAFE_style={{ cursor: "pointer", border: "none" }}>

                    <Link size="S" />
                  </Button></Cell>
                </Row>
              ))}
            </TableBody>
          </TableView>
        )}
        {defaultData && accountInfo.length === 0 && (
          <p style={{ textAlign: "center", marginTop: "20px" }}>
            No accounting data available.
          </p>
        )}
      </Flex>
    </>

  );
};

export default Accounting;
